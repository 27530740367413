import { computed, reactive, ref, nextTick } from '@nuxtjs/composition-api'
import { Config } from '@vuex-orm/plugin-axios'
import { useNotify, useRole } from '../hooks'
import { processErrors } from '../errors'
import { useVuexPathify } from '../hooks/store'
import User from '~/models/user'

export const useUserInteractor = () => {
  const users = ref<User[]>([])
  const notify = useNotify()
  const userRole = useRole()
  const { get, set } = useVuexPathify()
  const loggedInUserInStore = get<User>('loggedInUser')
  const loggedInUser = computed(() => User.query()
    .with('organization')
    .find(loggedInUserInStore.value.id)
  )

  const loadingStatus = reactive({
    saving: false,
    fetching: false
  })

  const fetch = async (config: Config) => {
    try {
      loadingStatus.fetching = true
      const res = await User.fetchAll({ save: false, ...config }, userRole)
      const data = res.getDataFromResponse()
      if (Array.isArray(data)) {
        users.value = data.map(item => new User(item))
      }
      return data
    } catch (e) {
      processErrors(e, notify)
    } finally {
      loadingStatus.fetching = false
    }
  }

  const updateProfile = async (payload: Partial<User>, config?: Config & {throwErrors: boolean}) => {
    try {
      loadingStatus.saving = true
      const res = await User.updateProfile(payload, config)
      await nextTick()
      set('loggedInUser', loggedInUser.value)
      return res
    } catch (e) {
      if (config?.throwErrors) {
        throw e
      }
      processErrors(e, notify)
    } finally {
      loadingStatus.saving = false
    }
  }

  return { loggedInUser, users, loadingStatus, fetch, updateProfile }
}
