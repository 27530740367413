



































import { defineComponent, reactive, computed, useRouter } from '@nuxtjs/composition-api'
import { useI18n } from '~/composables/hooks'

export default defineComponent({
  setup (_, { emit }) {
    const { i18n, switchLocalePath } = useI18n()
    const router = useRouter()
    const items = reactive<Record<string, {flag: string, title: string}>>({
      en: {
        flag: 'flagEN.jpg',
        title: 'English'
      },
      nl: {
        flag: 'flagNL.jpg',
        title: 'Nederlands'
      },
      de: {
        flag: 'flagDE.jpg',
        title: 'Deutsch'
      }
    })

    const currentLocale = computed({
      get () {
        return i18n.locale
      },
      set (val: string) {
        if (typeof val === 'undefined') { return }
        router.push(switchLocalePath(val))
        emit('switched-locale', val)
      }
    })
    return { items, currentLocale }
  }
})
