import { ActionTree, MutationTree } from 'vuex'

import { DataOptions } from 'vuetify/types'
import { make } from 'vuex-pathify'
import { RootState } from '~/store'

export const defaultTableOptions: DataOptions = {
  page: 1,
  itemsPerPage: 20,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: true,
  mustSort: false
}

const defaultShipmentsFilters = {
  assignee: null,
  coordinator: null,
  status: null
}

const defaultTasksFilters = {
  assignee: null,
  completed: false
}

export const state = () => ({
  inquiries: {
    admin: {
      search: ''
    },
    shipper: {
      search: ''
    }
  },
  shipments: {
    admin: {
      search: '',
      filters: { ...defaultShipmentsFilters },
      tableOptions: { ...defaultTableOptions },
      exact: true
    },
    shipper: {
      search: '',
      filters: { ...defaultShipmentsFilters },
      tableOptions: { ...defaultTableOptions },
      exact: true
    },
    forwarder: {
      search: '',
      tableOptions: { ...defaultTableOptions },
      filters: { ...defaultShipmentsFilters },
      exact: true
    },
    forwarderDocs: {
      search: ''
    },
    shipperOverview: {
      search: '',
      tableOptions: { ...defaultTableOptions }
    }
  },
  invoices: {
    admin: {
      search: '',
      tableOptions: { ...defaultTableOptions },
      filters: {
        status: null,
        collector: null,
        assignee: null
      }
    },
    shipper: {
      search: '',
      tableOptions: { ...defaultTableOptions },
      filters: {
        status: null
      }
    }
  },
  organizations: {
    admin: '',
    shipments: {
      admin: ''
    }
  },
  arrivals: {
    admin: {
      search: '',
      tableOptions: { ...defaultTableOptions, itemsPerPage: 50 },
      filters: { ...defaultShipmentsFilters }
    }
  },
  departures: {
    admin: {
      search: '',
      tableOptions: { ...defaultTableOptions, itemsPerPage: 50 },
      filters: { ...defaultShipmentsFilters }
    }
  },
  tasks: {
    admin: {
      filters: { ...defaultTasksFilters },
      search: ''
    },
    forwarder: {
      filters: { ...defaultTasksFilters },
      search: ''
    }
  }
})
export type SearchState = ReturnType<typeof state>
export const mutations: MutationTree<SearchState> = make.mutations(state)
export const actions: ActionTree<SearchState, RootState> = make.actions(state)
