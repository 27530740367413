/* eslint-disable camelcase */
import { Model } from '@vuex-orm/core'
import { Response, Config } from '@vuex-orm/plugin-axios'
import { omit, pick } from 'lodash-es'
import { UserRole } from '~/composables/enums'
import { ADMIN, FORWARDER, PROFILE, USER } from '~/config/api'
import Organization from '~/models/organization'

const endpoints: Record<UserRole, string> = {
  [UserRole.ADMIN]: ADMIN.USER,
  [UserRole.FORWARDER]: FORWARDER.USER,
  [UserRole.CLIENT]: ''
}

export default class User extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'users'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      deleted_at: this.attr(null),
      first_name: this.attr(''),
      last_name: this.attr(''),
      email: this.string(''),
      phone: this.string(''),
      language: this.string('en'),
      agreed_with_tac: this.attr(false),
      receive_promotions: this.attr(false),
      hubspot_contact_id: this.attr(''),
      auth0_id: this.attr(null),
      organization_id: this.number(0),
      organization: this.belongsTo(Organization, 'organization_id'),
      affiliate_id: this.number(0)
    }
  }

  id!: number
  created_at!: string
  updated_at!: string
  deleted_at!: string
  first_name!: string
  last_name!: string
  email!: string
  phone!: string
  language!: string
  agreed_with_tac!: boolean
  receive_promotions!: boolean
  hubspot_contact_id!: string
  auth0_id!: string|null
  organization_id!: number
  organization!: Organization
  affiliate_id!: number

  /**
   * Fetches a user's profile information
   *
   * @static
   * @param {Config} config
   * @returns {Promise<Response>}
   * @memberof Inquiry
   */
  public static getProfile (config?: Config): Promise<Response> {
    return this.api().get(PROFILE, config)
  }

  /**
   * Updates the user's profile information
   *
   * @static
   * @param {User} payload
   * @param {Config} [config]
   * @return {*}  {Promise<Response>}
   * @memberof User
   */
  public static updateProfile (user: Partial<User>, config?: Config): Promise<Response> {
    const allowed: (keyof User|`organization.${keyof User['organization']}`)[] = [
      'first_name', 'last_name', 'phone', 'email', 'agreed_with_tac', 'receive_promotions',
      'language', 'organization.name', 'organization.shipments_per_year']
    const payload = pick(user, allowed)
    return this.api().put(PROFILE, payload, config)
  }

  /**
   * Fetch users for admin
   *
   * @static
   * @param {Config} config
   * @returns {Promise<Response>}
   * @memberof User
   */
  public static fetchAll (config?: Config, role = UserRole.ADMIN): Promise<Response> {
    return this.api().get(endpoints[role], config)
  }

  /**
   * Update the record of the current user instance
   *
   * @param {Partial<User>} data
   * @param {Config} [config]
   * @return {Promise<Response>}
   * @memberof User
   */
  public async persist (data: Partial<User>, config?: Config): Promise<Response> {
    const endpoint = ADMIN.USER

    const originalRecord = { ...this }
    try {
      User.update({ where: this.id, data })
      return await User.api().patch(`${endpoint}/${this.id}`, omit(data, 'id'), config)
    } catch (e) {
      User.update({ where: this.id, data: originalRecord })
      throw e
    }
  }

  public static async getHubspotToken (config?: Config): Promise<string> {
    const response = await this.api().get(`${USER}/hubspottoken`, {
      ...config,
      save: false
    })
    return response.response.data.data
  }

  public get fullname () {
    return `${this.first_name} ${this.last_name}`
  }
}
