import { ActionTree, MutationTree } from 'vuex'
import { Database, install, use } from '@vuex-orm/core'
import pathify, { make } from 'vuex-pathify'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import PlatformMessage, { ReadStatus } from '~/models/message'
import Task, { TasksCount } from '~/models/task'

import Carrier from '~/models/carriers'
import Document from '~/models/document'
import DocumentSlot from '~/models/documentSlot'
import ExchangeRate from '~/models/exchangeRate'
import Holiday from '~/models/holiday'
import Inquiry from '~/models/inquiry'
import Invoice from '~/models/invoice'
import Note from '~/models/note'
import Organization from '~/models/organization'
import Payment from '~/models/payment'
import Quote from '~/models/quote'
import Review from '~/models/review'
import Shipment from '~/models/shipment'
import ShipmentInvoiceSummary from '~/models/shipmentInvoiceSummary'
import { ShipmentStageModel } from '~/models/shipmentStage'
import User from '~/models/user'

use(VuexORMAxios, {
  dataKey: 'data'
})

const database = new Database()
database.register(Inquiry)
database.register(User)
database.register(Organization)
database.register(Shipment)
database.register(Quote)
database.register(Invoice)
database.register(Payment)
database.register(Document)
database.register(DocumentSlot)
database.register(Review)
database.register(ExchangeRate)
database.register(Review)
database.register(Carrier)
database.register(ShipmentInvoiceSummary)
database.register(ShipmentStageModel)
database.register(PlatformMessage)
database.register(ReadStatus)
database.register(Note)
database.register(Task)
database.register(TasksCount)
database.register(Holiday)
export const plugins = [
  pathify.plugin,
  install(database)
]

export const state = () => ({
  cookieWarningVisible: false,
  cookieWarningShown: false,
  userLocation: {},
  miniVariant: true,
  loggedInUser: new User()
})

export type RootState = ReturnType<typeof state>
export const mutations: MutationTree<RootState> = make.mutations(state)
export const actions: ActionTree<RootState, RootState> = make.actions(state)
