import { computed, wrapProperty } from '@nuxtjs/composition-api'
import { LocaleObject } from '@nuxtjs/i18n'
import { useVuexPathify } from './store'
import { wrapGtm } from '~/composables/helpers/gtm'
import { Message } from '~/store/notifications'

export function useNotify () {
  const { dispatch } = useVuexPathify()
  return function (message: Partial<Message>) {
    return dispatch('notifications/notify', message)
  }
}

export const useAxios = wrapProperty('$axios', false)
export const useVuetify = wrapProperty('$vuetify', false)
export const useConfig = wrapProperty('$config', false)

export function useI18n () {
  const i18n = wrapProperty('$i18n', false)()
  return {
    i18n,
    localePath: wrapProperty('localePath')().value,
    switchLocalePath: wrapProperty('switchLocalePath')().value,
    localeISO: computed(() => {
      if (typeof i18n.locales !== 'undefined' && i18n.locales.length) {
        return (i18n.locales as LocaleObject[]).find((l: LocaleObject) => {
          return l.code === i18n.locale
        })?.iso || 'en-US'
      }
      return 'en-US'
    }).value
  }
}

export function useGtm (userProvided = true) {
  // @ts-ignore: declare the $gtm type in a .d.ts file
  const gtm = wrapProperty('$gtm', false)()
  return wrapGtm(gtm, userProvided)
}

export * from './injections'
