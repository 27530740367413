import { Ref, isRef } from '@nuxtjs/composition-api'
import { enUS, nl } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'

import { Md5 } from 'ts-md5'
import { debounce } from 'lodash-es'
// import i18n from '~/config/i18n'
import isEmail from 'validator/es/lib/isEmail'
import Shipment from '../models/shipment'
import { useCountries, useI18n, useRole } from './hooks'
import { ProtoDate, ProtoDateTime, ProtoPostalAddress } from './types'

export const getGravatar = (email: string, options: {size?: number, fallback?: string} = {}) =>
  `https://www.gravatar.com/avatar/${Md5.hashStr(email)}.jpg?s=${options.size ?? 80}&d=${options.fallback ?? 'mp'}`

export const identifyUserForHubspot = (email: string) => {
  const wdw = window as any // Eliminate ts inference for Window
  const _hsq = wdw._hsq = wdw._hsq || []
  if (isEmail(email)) {
    _hsq.push(['identify', { email }])
    _hsq.push(['trackPageView'])
  }
}

export const setHubspotChatConfig = (email: string, token: string) => {
  const wdw = window as any // Eliminate ts inference for Window
  if (wdw.HubSpotConversations) {
    wdw.hsConversationsSettings = {
      identificationEmail: email,
      identificationToken: token
    }
  }
}

export const loadHubspotChatBubble = () => {
  // Load with a delay to ensure the widget is loaded after the page is fully rendered
  setTimeout(() => {
    const wdw = window as any // Eliminate ts inference for Window
    if (wdw.HubSpotConversations) {
      wdw.HubSpotConversations.widget.load()
    }
  }, 2000)
}

export const debouncedUpdate = <Model extends {}, Field extends keyof Model>(model: Ref<Model>|Model, field: Field, delay = 250) => debounce((value: Model[Field]) => {
  if (isRef(model)) {
    model.value[field] = value
  } else {
    model[field] = value
  }
}, delay)

export const filterStatusOptions = (allOptions: Record<string, string>, allowedOptions: any[]): Record<string, string> => {
  const allowedStatusOptions = allowedOptions.map(item => item.toString())
  return Object.entries(allOptions).reduce((result, [key, value]) => {
    if (allowedStatusOptions.includes(key)) {
      result[key] = value
    }
    return result
  }, {} as Record<string, string>)
}

export const protoDateTimeToDate = (protoDate: ProtoDateTime): Date => {
  return new Date(Date.UTC(
    protoDate.year, protoDate.month - 1, protoDate.day, protoDate.hours ?? 0, protoDate.minutes ?? 0, protoDate.seconds ?? 0, Math.floor((protoDate.nanos ?? 0) / 1e6)
  ))
}

export const formatLocaleDate = (date: string, formatString: string = 'dd MMMM, yyyy') => {
  if (!date) {
    return ''
  }

  const formattedDate = typeof date === 'string' ? new Date(date) : parseISO(date)
  const locale: Record<string, any> = { en: enUS, nl }
  const { i18n } = useI18n()
  const dateFnsLocale = locale[i18n.locale]

  return format(formattedDate, formatString, { locale: dateFnsLocale })
}

export const dateToProtoDateTime = (date: Date): ProtoDateTime => {
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    day: date.getUTCDate(),
    hours: date.getUTCHours(),
    minutes: date.getUTCMinutes(),
    seconds: date.getUTCSeconds(),
    nanos: date.getUTCMilliseconds() * 1e6
  }
}

export const protoDateToDate = (protoDate: ProtoDate): Date => {
  return new Date(Date.UTC(
    protoDate.year, protoDate.month - 1, protoDate.day
  ))
}

export const dateToProtoDate = (date: Date): ProtoDate => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  }
}

export const envIsProduction = () => window.location.hostname === 'app.cargoplot.com'

export const htmlDecode = (val: string) => val
  ? new DOMParser().parseFromString(val, 'text/html').documentElement.textContent
  : val

export const nl2br = (str: string) => str.replace(/(?:\r\n|\r|\n)/g, '<br>')

function isProto (addr: ProtoPostalAddress|Location|Shipment['destination']): addr is ProtoPostalAddress {
  return 'region_code' in addr
}

export const protoLocationToString = (address: ProtoPostalAddress|Shipment['destination']): string => {
  if (!isProto(address)) { return `${address.route} ${address.street_number}, ${address.postal_code}, ${address.locality}, ${address.administrative_area_level_1}, ${address.country}` }

  const countries = useCountries()
  const { i18n } = useI18n()
  return [
    address.organization,
    address.address_lines.join(', '),
    address.postal_code,
    address.locality,
    address.administrative_area,
    countries.getName(address.region_code, i18n.locale)]
    .filter(v => !!v)
    .join(', ')
}
