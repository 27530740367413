import { cloneDeep, omit, pick } from 'lodash-es'
import { Config } from '@vuex-orm/plugin-axios'
import { formatISO } from 'date-fns'
import CargoplotModel, { Usage } from './cargoplot'
import Location, { newLocation } from './location'
import Quote, { QuoteSource } from './quote'

import CargoComponent from './cargoComponent'
/* eslint-disable camelcase */
import Container from './container'
import { ReadStatus } from './message'
import User from './user'
import { CargoTypes, Contents, Currency, Incoterms, InquiryStatus, QuoteStatus, TransportModes, UserRole } from '~/composables/enums'
import { ADMIN, INQUIRY } from '~/config/api'

const endpoints: Record<UserRole, string> = {
  [UserRole.ADMIN]: ADMIN.INQUIRY,
  [UserRole.CLIENT]: INQUIRY,
  [UserRole.FORWARDER]: ''
}

const defaultPayloadOmits = ['id', '$id', 'created_at', 'deleted_at', 'updated_at']

export default class Inquiry extends CargoplotModel {
  static entity = 'inquiries'

  static state () {
    return {
      adminFetchTime: '',
      adminCursor: '',
      clientFetchTime: '',
      clientCursor: ''
    }
  }

  static fields () {
    return {
      id: this.attr(0),
      identifier: this.attr(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      deleted_at: this.attr(null),
      business: this.boolean(true),
      status: this.number(0),
      user_id: this.number(0),
      user: this.belongsTo(User, 'user_id'),
      description: this.string(''),
      special_contents: this.attr([]),
      transport_date: this.attr(''),
      transport_modes: this.attr([]),
      incoterm: this.number(0),
      incoterms: this.attr([]),
      total_weight: this.number(0),
      total_volume: this.number(0),
      hubspot_deal_id: this.attr(''),
      cargo_type: this.string(''),
      origin: this.attr(newLocation()),
      destination: this.attr(newLocation()),
      cargo_components: this.attr([]),
      carriers: this.attr([]),
      containers: this.attr([]),
      quotes: this.hasMany(Quote, 'inquiry_id'),
      port_of_loading: this.attr(''),
      port_of_discharge: this.attr(''),
      quote_sorting: this.string('price_asc'),
      min_free_demurrage_days: this.number(4),
      min_free_detention_days: this.number(4),
      max_transit_days: this.number(45),
      min_review_score: this.number(1),
      hs_code_count: this.number(1),
      container_decoupling: this.boolean(false),
      regular_truck_delivery: this.boolean(false),
      tail_lift: this.boolean(false),
      timeslot_delivery: this.boolean(false),
      carbon_offset_compensation: this.boolean(false),
      pallet_exchange: this.boolean(false),
      gas_measurement: this.boolean(false),
      insured_goods_value_cents: this.number(0),
      insured_goods_currency: this.attr(Currency.EUR),
      assignee_id: this.number(null),
      assignee: this.belongsTo(User, 'assignee_id'),
      read_status: this.hasOne(ReadStatus, 'cargoplot_reference', 'identifier'),
      storage: this.boolean(false),
      side_loader_delivery: this.boolean(false)
    }
  }

  id!: number
  identifier!: string
  created_at!: string
  updated_at!: string
  deleted_at!: string | null
  business!: boolean
  fulfillment!: boolean
  status!: InquiryStatus
  user_id!: User['id']
  user!: User
  description!: string
  special_contents!: Contents[]
  transport_date!: string
  transport_modes!: TransportModes[]
  incoterm!: Incoterms
  incoterms?: Incoterms[]
  total_weight!: number
  total_volume!: number
  hubspot_deal_id!: string
  cargo_type!: CargoTypes
  origin!: Location
  destination!: Location
  cargo_components?: CargoComponent[]
  carriers?: string[]
  containers?: Container[]
  quotes?: Quote[]
  port_of_loading?: string
  port_of_discharge?: string
  quote_sorting!: string
  min_free_demurrage_days!: number
  min_free_detention_days!: number
  max_transit_days!: number
  min_review_score!: number
  hs_code_count!: number
  container_decoupling!: boolean
  regular_truck_delivery!: boolean
  tail_lift!: boolean
  timeslot_delivery!: boolean
  carbon_offset_compensation!: boolean
  pallet_exchange!: boolean
  gas_measurement!: boolean
  insured_goods_value_cents!: number
  insured_goods_currency!: Currency|null
  assignee_id?: number
  assignee?: User|null
  read_status?: ReadStatus
  storage!: boolean
  side_loader_delivery!: boolean

  /**
   * Fetch all inquiries (cargoplot employees)
   * If limit is passed as query param, the results will be paginated.
   *
   * @static
   * @param {Config} config
   * @returns {Promise<Response>}
   * @memberof Inquiry
   */
  public static fetchAll (config?: Config, role = UserRole.CLIENT, cursorUsage: Usage = 'save', reqTimeUsage: Usage = 'saveAndSend') {
    const cursorVar = `${UserRole[role].toLowerCase()}Cursor`
    const fetchTimeVar = `${UserRole[role].toLowerCase()}FetchTime`
    const endpoint = endpoints[role]

    const cursor = { field: cursorVar, usage: cursorUsage }
    const lastRequestTime = { field: fetchTimeVar, usage: reqTimeUsage }

    // If only new data is fetched, don't send a limit and don't use the cursor
    if (['send', 'saveAndSend'].includes(reqTimeUsage) && this.getState().adminFetchTime) {
      delete config?.params?.limit
      cursor.usage = 'none'
    }

    return this.fetch(
      endpoint,
      config,
      lastRequestTime,
      cursor
    )
  }

  /**
   * Fetch a single inquiry by ID
   * @param id The ID of the inquiry
   * @param config The axios or vuex-orm config parameters
   * @returns A response object containing the data
   */
  public static fetchOneForUser (id :string|number, config?: Config) {
    return this.api().get(`${INQUIRY}/${id}`, config)
  }

  /**
   * Fetch a single inquiry by ID for admin users
   * @param id The ID of the inquiry
   * @param config The axios or vuex-orm config parameters
   * @returns A response object containing the data
   */
  public static fetchOne (id: string|number, config?: Config) {
    return this.api().get(`${ADMIN.INQUIRY}/${id}`, config)
  }

  /**
   * Update the record of the current inquiry instance
   *
   * @param {Partial<Inquiry>} data
   * @param {Config} [config]
   * @param {boolean} [admin=false]
   * @return {*}  {Promise<Response>}
   * @memberof Inquiry
   */
  public async persist (data: Partial<Inquiry>, config?: Config, role = UserRole.CLIENT) {
    const originalRecord = { ...this }
    try {
      Inquiry.update({ where: this.id, data })
      return await Inquiry.api().put(`${endpoints[role]}/${this.id}`, sanitize(data, role), config)
    } catch (e) {
      Inquiry.update({ where: this.id, data: originalRecord })
      throw e
    }
  }

  // For new way of creating inquiries (e.g. only the four main fields in marketplace inquiry form)
  public static make (data: Partial<Inquiry>, config?: Config, role = UserRole.CLIENT) {
    const fields: (keyof Inquiry)[] = [
      'cargo_type', 'origin', 'destination', 'transport_date', 'containers', 'cargo_components', 'description',
      'transport_modes', 'incoterms'
    ]
    if (role === UserRole.ADMIN) {
      fields.push('user_id')
    }
    data = pick(data, fields)
    return Inquiry.api().post(endpoints[role], sanitize(data, role), config)
  }

  // For duplicating inquiries. Posts full set of data
  public static duplicate (data: Partial<Inquiry>, config?: Config, role = UserRole.CLIENT) {
    const fields: (keyof Inquiry)[] = ['status', 'assignee_id']
    data = omit(data, fields)
    return Inquiry.api().post(endpoints[role], sanitize(data, role), config)
  }

  public static parsePdf (filedata: File) {
    const formData = new FormData()
    formData.append('document', filedata)
    return Inquiry.api().post(`${INQUIRY}/parsePdf`, formData, {
      save: false,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  /**
   * Delete an inquiry (soft-delete)
   */
  public destroy (role = UserRole.CLIENT) {
    return Inquiry.api().delete(`${endpoints[role]}/${this.id}`, {
      delete: this.id
    })
  }

  public static get (id: string|number, role = UserRole.CLIENT) {
    const q = this.query()
      .where((inquiry: Inquiry) => inquiry.identifier === id || inquiry.id === Number.parseInt(id.toString()))
      .where('cargo_type', (value: string) => value !== '')
      .with('quotes', (query) => {
        query
          .where('cargo_type', (value: string) => value !== '')
          .where('source', (value: QuoteSource) => value !== QuoteSource.QuoteEngine)
          .with('forwarder')
          .orderBy('status', 'asc')
        if (role === UserRole.CLIENT) {
          query.where('status', (value: QuoteStatus) => value !== QuoteStatus.STATUS_DRAFT)
          query.orderBy((quote:any) => quote.tags?.includes('familiar-agents') ? 1 : 0, 'desc')
        }
        query.orderBy('total_price_euro_cents_inc', 'asc')
      })

    if (role === UserRole.ADMIN) {
      q.with('user.organization').with('assignee')
    }
    return q.first()
  }
}

function sanitize (data: Partial<Inquiry>, role = UserRole.CLIENT) {
  const result = omit(cloneDeep(data), [...defaultPayloadOmits, 'business',
    'hubspot_deal_id', 'identifier', 'total_volume', 'total_weight', 'user', 'quotes', 'assignee', 'read_status'
  ])
  // Don't send status on create
  if (role === UserRole.CLIENT) {
    delete result.user_id
    delete result.assignee_id
  }
  if (result.origin) {
    result.origin = omit(result.origin, defaultPayloadOmits) as Location
  }
  if (result.destination) {
    result.destination = omit(result.destination, defaultPayloadOmits) as Location
  }
  if (result.cargo_type === CargoTypes.CONTAINERS) {
    result.containers = result.containers?.map(ctr => pick(ctr, ['container_type', 'quantity']))
    delete result.cargo_components
  } else {
    result.cargo_components = result.cargo_components?.map(
      item => pick(item, ['weight', 'height', 'width', 'depth', 'quantity'])
    )
    delete result.containers
  }
  if (result.transport_date) {
    result.transport_date = formatISO(new Date(result.transport_date))
  }
  return result
}
