/* eslint-disable camelcase */
import { Model } from '@vuex-orm/core'
import { Config } from '@vuex-orm/plugin-axios'
import Shipment from './shipment'
import { ADMIN } from '~/config/api'

interface TotalAmountCents {
  EUR: number
}

export default class ShipmentInvoiceSummary extends Model {
  static entity = 'shipmentInvoiceSummary'
  static primaryKey = 'shipment_id'

  static fields () {
    return {
      shipment_id: this.number(0),
      organization_id: this.number(0),
      shipment: this.belongsTo(Shipment, 'shipment_id'),
      open: this.number(0),
      open_with_eta_soon: this.number(0),
      overdue: this.number(0),
      total_amount_cents_open: this.number(null),
      total_amount_cents_overdue: this.number(null),
      shipment_days_to_eta: this.number(0)
    }
  }

  shipment_id!: number
  organization_id!: number
  open!: number
  open_with_eta_soon!: number
  overdue!: number
  total_amount_cents_open?: TotalAmountCents | null
  total_amount_cents_overdue?: TotalAmountCents | null
  shipment_days_to_eta!: number

  public static get (shipmentId: number) {
    return this.query()
      .where('shipment_id', shipmentId)
      .first()
  }

  public static getAllForOrganization (organizationId: number) {
    return this.query()
      .where('organization_id', organizationId)
      .all()
  }

  /**
   * Fetch invoice summary for shipments within the organizations with provided IDs
   * @param organizationIds The IDs of the organizations
   * @param config The axios or vuex-orm config parameters
   * @returns A response object containing the data
   */
  public static async fetchInvoiceSummary (organizationIds: number[], config?: Config) {
    if (!organizationIds.length) { return [] }

    const response = await this.api().get(ADMIN.INVOICE_SUMMARY, {
      ...config,
      params: { org_id: organizationIds.join(',') }
    })
    return (response.getDataFromResponse() as ShipmentInvoiceSummary[] || [])
  }
}
