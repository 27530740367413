import {
  mdiAirplane,
  mdiFerry,
  mdiFile,
  mdiFileDocument,
  mdiFileExcel,
  mdiFileImage,
  mdiFileMusic,
  mdiFilePdfBox,
  mdiFileVideo,
  mdiFileWord,
  mdiHelp,
  mdiPackageVariantClosed,
  mdiShippingPallet,
  mdiTrain,
  mdiTrainCarContainer,
  mdiTruck
} from '@mdi/js'
import { CargoTypes, TransportModes } from './enums'

export const transportModeIcons: Record<TransportModes, string> = {
  [TransportModes.SEA]: mdiFerry,
  [TransportModes.AIR]: mdiAirplane,
  [TransportModes.RAIL]: mdiTrain,
  [TransportModes.ROAD]: mdiTruck
} as const

// Determine the correct icon to display depending on the file mimetype
export const getFileIcon = (mimeType: string) => {
  // if mimetype is pdf
  if (mimeType === 'application/pdf') {
    return mdiFilePdfBox
  } else if (mimeType.startsWith('text')) {
    return mdiFileDocument
    // word files
  } else if (mimeType.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml')) {
    return mdiFileWord
    // excel files
  } else if (mimeType.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml')) {
    return mdiFileExcel
  } else if (mimeType.startsWith('image')) {
    return mdiFileImage
  } else if (mimeType.startsWith('video')) {
    return mdiFileVideo
  } else if (mimeType.startsWith('audio')) {
    return mdiFileMusic
  }
  return mdiFile
}

export const cargoTypeIcons: Record<CargoTypes, string> = {
  [CargoTypes.CONTAINERS]: mdiTrainCarContainer,
  [CargoTypes.PALLETS]: mdiShippingPallet,
  [CargoTypes.PACKAGES]: mdiPackageVariantClosed,
  [CargoTypes.UNSPECIFIED]: mdiHelp
}
