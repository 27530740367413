import { Config } from '@vuex-orm/plugin-axios'
import { Model } from '@vuex-orm/core'
import { HOLIDAY } from '~/config/api'
/* eslint-disable camelcase */

interface HolidayName {
  language: string
  text: string
}

export default class Holiday extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'holidays'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.string(''),
      startDate: this.string(''),
      endDate: this.string(''),
      type: this.string(''),
      name: this.attr([]),
      regionalScope: this.string(''),
      temporalScope: this.string(''),
      nationwide: this.boolean(false)
    }
  }

  id!: string
  startDate!: string
  endDate!: string
  type!: string
  name!: HolidayName[]
  regionalScope!: string
  temporalScope!: string
  nationwide!: boolean

  /**
   * Fetch public holidays for a country
   * @param countryCode The country code
   * @param config The axios or vuex-orm config parameters
   * @returns A response object containing the data
   */
  static async fetch (countryCode: string, config?: Config) {
    const response = await this.api().get(`${HOLIDAY}/${countryCode}`, config)
    return (response.getDataFromResponse() as Holiday[] || [])
  }

  public static getNationalHolidays () {
    return Holiday.query()
      .where('nationwide', true)
      .all()
  }
}
