import { Config } from '@vuex-orm/plugin-axios'
import { pick } from 'lodash-es'
import CargoplotModel from './cargoplot'

/* eslint-disable camelcase */
import LineItem from './lineItem'
import Organization from './organization'
import Payment from './payment'
import Shipment from './shipment'
import User from './user'
import { VAT } from './vat'
import { Collector, Currency, InvoiceStatus, UserRole } from '~/composables/enums'
import { ADMIN, INVOICE } from '~/config/api'

/**
 * This is different from InvoiceStatus or PaymentStatus,
 * this is status of invoices based on the payment situation and due date of the invoice to show on the panel and dialog.
 */
export enum InvoiceChipStatus {
  STATUS_UNSPECIFIED,
  STATUS_DRAFT,
  STATUS_PUBLISHED,
  STATUS_PAID,
  STATUS_ALMOSTDUE,
  STATUS_OVERDUE,
  STATUS_DISPUTED,
  STATUS_IN_COLLECTION,
}

const endpoints: Record<UserRole, string> = {
  [UserRole.ADMIN]: ADMIN.INVOICE,
  [UserRole.CLIENT]: INVOICE,
  [UserRole.FORWARDER]: ''
}

export default class Invoice extends CargoplotModel {
  // This is the name used as module name of the Vuex Store.
  static entity = 'invoices'

  static state () {
    return {
      clientFetchTime: '',
      adminFetchTime: ''
    }
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      deleted_at: this.attr(null),
      updated_at: this.attr(''),
      agent_fee_cents: this.number(0),
      collector: this.number(1),
      currency: this.attr(Currency.EUR),
      due_date: this.attr(null),
      last_payment_reminder: this.attr(null),
      reminders_sent: this.number(0),
      line_items: this.attr([]),
      notes: this.attr(''),
      notes_count: this.number(0),
      open_amount_cents: this.number(0),
      organization: this.belongsTo(Organization, 'organization_id'),
      organization_id: this.attr(null),
      organization_snapshot: this.attr({}),
      payments: this.hasMany(Payment, 'invoice_id'),
      pdf_uri: this.attr(''),
      published_at: this.attr(null),
      published_by_user_id: this.attr(null),
      publisher: this.belongsTo(User, 'published_by_user_id'),
      reference: this.attr(''),
      shipment: this.belongsTo(Shipment, 'shipment_id'),
      shipment_id: this.attr(null),
      shipment_snapshot: this.attr({}),
      status: this.number(0),
      total_price_cents_ex: this.number(0),
      total_price_cents_inc: this.number(0),
      total_vat: this.attr([]),
      block_reminders_until: this.attr(null)
    }
  }

  id!: number
  created_at!: string
  deleted_at?: string
  updated_at!: string
  agent_fee_cents!: number
  collector!: Collector
  currency!: Currency
  due_date?: string
  issue_date?: string
  last_payment_reminder?: string
  reminders_sent!: number
  line_items!: LineItem[]
  notes?: string
  notes_count!: number
  open_amount_cents!: number
  organization?: Organization
  organization_id!: number
  organization_snapshot!: Organization
  payments?: Payment[]
  pdf_uri?: string
  published_at?: string
  published_by_user_id?: number
  publisher?: User
  reference!: string
  shipment?: Shipment
  shipment_id?: number
  shipment_snapshot!: Shipment
  status!: InvoiceStatus
  total_price_cents_ex!: number
  total_price_cents_inc!: number
  total_vat?: VAT[]
  block_reminders_until?: string|null

  // Fetch overview of invoices
  public static fetchAll (config?: Config, role = UserRole.CLIENT) {
    return this.fetch(
      endpoints[role],
      config,
      { field: `${UserRole[role].toLowerCase()}FetchTime`, usage: 'saveAndSend' }
    )
  }

  // Create a new invoice
  public static make (payload: Invoice, config?: Config) {
    return Invoice.api().post(
      ADMIN.INVOICE,
      this.sanitize(payload, 'create'),
      config
    )
  }

  // Update an existing invoice
  public async persist (payload: Invoice, config?: Config, optimistic = true) {
    const originalRecord = this.$toJson()
    if (optimistic) {
      await this.$update(payload.$toJson())
    }
    try {
      return await Invoice.api().put(
        `${ADMIN.INVOICE}/${this.id}`,
        Invoice.sanitize(payload, 'update'),
        config
      )
    } catch (e) {
      if (optimistic) {
        this.$update(originalRecord)
      }
      throw e
    }
  }

  // Delete the specified invoice
  public static remove (id: number|string) {
    return this.api().delete(`${ADMIN.INVOICE}/${id}`, { delete: id })
  }

  /**
   * Download an invoice
   * @static
   * @param id The ID of the invoiceto download
   * @param config The axios or vuex-orm config parameters
   * @returns A response object containing the data
   * @memberof Invoice
   */
  public download (role = UserRole.CLIENT, config?: Config) {
    const headers = {
      'Content-Type': 'application/octet-stream'
    }
    return Invoice.api().get(`${endpoints[role]}/${this.id}/download`, {
      responseType: 'arraybuffer', headers, save: false, ...config
    })
  }

  // Process the payment for the specified invoice
  public static pay (id: number|string, config?: Config, role = UserRole.CLIENT) {
    return this.api().post(`${endpoints[role]}/${id}/pay`, {}, { save: false, ...config })
  }

  public static paymentReminder (id: number|string, config?: Config) {
    return this.api().post(`${ADMIN.INVOICE}/${id}/payment-reminder`, {}, config)
  }

  /**
   * Fetch a single invoice by ID
   * @param id The ID of the invoice
   * @param config The axios or vuex-orm config parameters
   * @param role
   * @returns A response object containing the data
   */
  public static fetchOne (id?:string|number, config?: Config, role = UserRole.CLIENT) {
    return this.api().get(`${endpoints[role]}/${id}`, config)
  }

  public fetchSendLog () {
    return Invoice.api().get(`${endpoints[UserRole.ADMIN]}/${this.id}/sendlog`, { save: false })
  }

  public static sanitize (payload: Invoice, mode: 'create'|'update'): Partial<Invoice> {
    const pickFields: (keyof Invoice)[] = [
      'notes', 'due_date', 'agent_fee_cents', 'line_items', 'collector', 'total_vat',
      'total_price_cents_ex', 'total_price_cents_inc', 'currency', 'block_reminders_until'
    ]
    if (mode === 'update') {
      pickFields.push('status')
    } else {
      pickFields.push('shipment_id')
    }
    const data = pick(payload, pickFields)
    const lineItemFields: (keyof LineItem)[] = [
      'quantity', 'unit', 'unit_price_cents', 'price_cents_ex', 'currency', 'description',
      'vat_category', 'vat_rate'
    ]
    data.line_items = data.line_items.map((item) => {
      const ple = pick(item, lineItemFields)
      ple.currency = payload.currency
      ple.price_cents_ex = Math.round(item.converted_price_cents_ex ?? item.price_cents_ex)
      ple.unit_price_cents = Math.round(item.converted_unit_price_cents ?? item.unit_price_cents)
      return ple
    })

    data.total_price_cents_ex = Math.round(data.total_price_cents_ex)
    data.total_price_cents_inc = Math.round(data.total_price_cents_inc)
    data.agent_fee_cents = Math.round(data.agent_fee_cents)

    return data
  }
}
