const ADMIN_BASE_PATH = '/admin'
const FORWARDER_BASE_PATH = '/forwarder'

export const USER = '/user'
export const INQUIRY = '/inquiry'
export const PUBLIC_INQUIRY = `${INQUIRY}/public`
export const SHIPMENT = '/shipment'
export const QUOTE = '/quote'
export const ORGANIZATION = '/organization'
export const PROFILE = '/profile'
export const GOOGLE_GEOLOCATION_URL = 'https://www.googleapis.com/geolocation/v1/geolocate'
export const DOCUMENT = '/document'
export const DOCSLOT = '/docslot'
export const INVOICE = '/invoice'
export const REVIEW = '/review'
export const MESSAGE = '/message'
export const WEBSOCKET = '/ws'
export const NOTE = '/note'
export const TASK = '/task'
export const HOLIDAY = '/public-holidays'

export const ADMIN = {
  INQUIRY: `${ADMIN_BASE_PATH}${INQUIRY}`,
  SHIPMENT: `${ADMIN_BASE_PATH}${SHIPMENT}`,
  ORGANIZATION: `${ADMIN_BASE_PATH}${ORGANIZATION}`,
  QUOTE: `${ADMIN_BASE_PATH}${QUOTE}`,
  USER: `${ADMIN_BASE_PATH}${USER}`,
  DOCSLOT: `${ADMIN_BASE_PATH}${DOCSLOT}`,
  DOCUMENT: `${ADMIN_BASE_PATH}${DOCUMENT}`,
  HUBSPOT_DEAL: `${ADMIN_BASE_PATH}/hubspot/deal`,
  EXCHANGERATE: `${ADMIN_BASE_PATH}/exchange-rate`,
  PRICEUPDATE: `${ADMIN_BASE_PATH}/price-update`,
  INVOICE: `${ADMIN_BASE_PATH}${INVOICE}`,
  INVOICE_SUMMARY: `${ADMIN_BASE_PATH}/organizations/invoice-summary`,
  REVIEW: `${ADMIN_BASE_PATH}${REVIEW}`,
  CARRIERS: `${ADMIN_BASE_PATH}/carriers`,
  ARRIVALS: `${ADMIN_BASE_PATH}/arrivals`,
  DEPARTURES: `${ADMIN_BASE_PATH}/departures`,
  MESSAGE: `${ADMIN_BASE_PATH}${MESSAGE}`,
  NOTE: `${ADMIN_BASE_PATH}${NOTE}`,
  TASK: `${ADMIN_BASE_PATH}${TASK}`
}

export const FORWARDER = {
  SHIPMENT: `${FORWARDER_BASE_PATH}${SHIPMENT}`,
  DOCSLOT: `${FORWARDER_BASE_PATH}${DOCSLOT}`,
  DOCUMENT: `${FORWARDER_BASE_PATH}${DOCUMENT}`,
  MESSAGE: `${FORWARDER_BASE_PATH}${MESSAGE}`,
  NOTE: `${FORWARDER_BASE_PATH}${NOTE}`,
  TASK: `${FORWARDER_BASE_PATH}${TASK}`,
  USER: `${FORWARDER_BASE_PATH}${USER}`
}
